
//https://cdnjs.com/libraries/jquery-jsonview
//https://github.com/abodelot/jquery.json-viewer
//https://github.com/yesmeck/jquery-jsonview

$(function(){

    $("pre.json").each(function() {
        var $this = $(this),
            raw_json = $this.html();

        $this.empty();

        $this.JSONView(raw_json, { collapsed: false });
    });

});


// // var data = {
// //   "foobar": "foobaz"
// // };
// // $('pre.json').jsonViewer(data);